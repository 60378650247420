<template>
  <header class="header nav-menu" noprint>
    <div class="inner primary-color" v-bind="getAllData">
      <div class="modulos-muestra" v-if="language">
        <div class="inner-brand logo-container">
          <router-link :to="{ path: '/', hash: '#home' }">
            <h1 class="third-font logo-container-title">{{ title }}</h1>
            <div class="logo-container-sub" v-if="language">
              <div v-for="(item, index) in language" :key="index" class="logo-container-item">
                <p class="logo-container-subitem">{{ item }}</p>
              </div>
            </div>
          </router-link>
        </div>
      </div>

      <ul class="inner-nav" v-if="headerData && isAllElements">
        <li class="inner-nav-menu">
          <ul class="display-flex" capitalize>
            <li>
              <router-link class="button" :to="{ path: '/', hash: '#home' }">
                {{ headerData.menu.home }}
              </router-link>
            </li>
            <li>
              <router-link class="button" :to="{ path: '/', hash: '#services' }">
                {{ headerData.menu.services }}
              </router-link>
            </li>
            <li>
              <router-link
                class="button"
                :to="{ path: '/webcast', hash: '#Webcast' }"
                :class="{
                  'router-link-exact-active': linkIsActive('/webcast'),
                }">
                {{ headerData.menu.webcast }}
              </router-link>
            </li>
            <li class="drop-down single hover ltr">
              <div
                class="button"
                :class="{
                  'router-link-exact-active': linkIsActive('/gallery') || linkIsActive('/help'),
                }">
                <label class="drop-down-label display-flex">
                  <span>{{ headerData.menu.gallery }}</span>
                  <i class="fa fa-caret-down right"></i>
                </label>
                <div class="drop-down-list list">
                  <router-link :to="{ path: '/gallery', hash: '#gallery' }" :class="{ 'router-link-exact-active': linkIsActive('/gallery') }">
                    {{ headerData.menu.gallery }}
                  </router-link>
                  <a @click="openMagazine"> {{ headerData.menu.magazine }} </a>
                  <router-link :to="{ path: '/', hash: '#galleryVideos' }">{{ headerData.menu.shortfilms }}</router-link>
                  <router-link :to="{ path: '/', hash: '#films' }">{{ headerData.menu.films }}</router-link>
                </div>
              </div>
            </li>
            <li>
              <router-link class="button" :to="{ path: '/', hash: '#testimonials' }">
                {{ headerData.menu.testimonials }}
              </router-link>
            </li>
            <li>
              <div class="button" @click="() => goToSiblingApp('global')">
                {{ headerData.menu.global }}
              </div>
            </li>
            <li>
              <div class="button" @click="() => goToSiblingApp('concurso')">
                {{ headerData.menu.contest }}
              </div>
            </li>
            <li>
              <router-link class="button" :to="{ path: '/blog', hash: '#blog' }" :class="{ 'router-link-exact-active': linkIsActive('/blog') }">
                {{ headerData.menu.blog }}
              </router-link>
            </li>
            <li class="drop-down single hover ltr">
              <div
                class="button"
                :class="{
                  'router-link-exact-active': linkIsActive('/contact') || linkIsActive('/help'),
                }">
                <label class="drop-down-label display-flex">
                  <span>{{ headerData.menu.contact }}</span>
                  <i class="fa fa-caret-down right"></i>
                </label>
                <div class="drop-down-list list">
                  <router-link :to="{ path: '/contact', hash: '#contact' }">
                    {{ headerData.menu.contact }}
                  </router-link>
                  <router-link :to="{ path: '/jobs', hash: '#jobs' }">{{ headerData.menu.jobs }}</router-link>
                  <router-link :to="{ path: '/help', hash: '#help' }">{{ headerData.menu.faq }}</router-link>
                </div>
              </div>
            </li>
            <li>
              <signup-button class="btn" :title="headerData.menu.signup" mclass="glowing"></signup-button>
            </li>
          </ul>
        </li>
        <li class="mbl-menu-btn">
          <a @click="mobileMenu">
            <div class="line"></div>
            <div class="line"></div>
            <div class="line"></div>
          </a>
        </li>
        <LangDropdown iconSize="35" :currentLocale="getLocale" :optsLang="headerData.languages" @localeChange="changeLang" class="inner-nav__languageModal" />
      </ul>
    </div>
  </header>
</template>

<script>
  import { mapActions, mapGetters } from "vuex";
  import store from "../../store/index.js";
  import SignupButton from "./SignupButton.vue";
  import { getHost } from "@/common/app.main";
  import { EventBus } from "../../helpers/eventBus";
  import LangDropdown from "./LangDropdown.vue";

  export default {
    nameLanguage: "Header",
    components: { SignupButton, LangDropdown },
    props: {
      msg: String,
      isAllElements: { type: Boolean, default: true },
    },
    data() {
      return {
        headerData: null,
        dataCart: null,
        mylang: localStorage.getItem("user-language").substring(0, 2) || navigator.language.substring(0, 2),
        language: null,
        title: null,
        lastScrollPosition: 0,
      };
    },
    mounted() {
      window.addEventListener("scroll", this.handleScroll);
    },
    beforeDestroy() {
      window.removeEventListener("scroll", this.handleScroll);
    },
    methods: {
      ...mapActions(["changeLanguage"]),
      openMagazine() {
        const params = {};

        if (this.$route.path !== "/") {
          params.path = "/";
          params.query = { openMagazine: true };
        }
        if (this.$route.hash !== "#ourcompany") {
          params.hash = "#ourcompany";
        }
        if (params.path || params.hash) {
          this.$router.push(params);
        } else {
          setTimeout(() => {
            EventBus.$emit("toggle-magazine-modal");
          }, 1000);
        }
      },
      goToSiblingApp(rootNav) {
        window.location.href = `/${rootNav}?lang=${this.getLocale}`;
      },
      handleScroll() {
        const header = document.querySelector(".header");
        const currentScrollPosition = window.pageYOffset;
        if (currentScrollPosition > 10) {
          if (currentScrollPosition > this.lastScrollPosition && currentScrollPosition !== 0) {
            header.classList.add("header--hidden");
            header.style.boxShadow = "none";
          } else {
            header.classList.remove("header--hidden");
            header.style.boxShadow = "";
          }
        }

        this.lastScrollPosition = currentScrollPosition;
      },
      mobileMenu: function () {
        let element = document.querySelector("body");
        element.classList.toggle("onmenu");
      },
      changeLang: async function (lang) {
        this.mylang = lang;
        await this.changeLanguage(lang);
      },
      dataHeader: async function () {
        if (store.getters["iflanguage"]) {
          let dataLang = await store.getters["dataLang"];
          this.headerData = dataLang.header;
        }
      },
      linkIsActive: function (input) {
        const paths = Array.isArray(input) ? input : [input];
        return paths.some((path) => {
          return this.$route.path.indexOf(path) === 0;
        });
      },
      getLang: async function () {
        if (store.getters["iflanguage"]) {
          let temp = await store.getters["localeLang"];
          this.title = temp.words.title;
          this.language = temp.words.header;
          this.language.forEach((item) => this.language.push(item));
        }
      },
    },
    computed: {
      ...mapGetters(["getLocale"]),
      getAllData: async function () {
        this.dataHeader();
        this.getLang();
      },
      appsLink: function () {
        return getHost();
      },
    },
  };
</script>
<style lang="scss">
  @import "@/assets/styles/vars.scss";
  @keyframes slide-in {
    from {
      transform: translateY(-100%);
    }
    to {
      transform: translateY(0);
    }
  }
  .header {
    position: fixed;
    z-index: 200;
    top: 0;
    left: 0;
    right: 0;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.7), transparent);
    .nav-menu {
      margin-left: -15px;
    }
    transition: transform 0.2s ease-in-out;
    transform: translateY(0%);
    &--hidden {
      transform: translateY(-100%);
      transition: transform 0.3s ease-in-out;
    }
    @include Desktop {
      &--hidden {
        transform: initial;
      }
    }
    .btn {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 10px;
      gap: 10px;
      width: 119px;
      height: 35px;
      border-radius: 20px;
      font-weight: bold;
    }
    .inner * {
      align-items: center;
    }
    .inner {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 4px 0;
      height: 70px;
      padding-right: 15px;

      &-brand {
        img {
          max-height: 30px;
          margin: 5px 0;
        }
      }
      &-nav {
        display: flex;
        align-items: center;
        appearance: none;
        list-style-type: none;

        &__languageModal {
          display: none;
        }
        &-apps {
          font-weight: 800;
        }
        > li {
          &:last-child {
            padding: 0 $mpadding;
          }
        }
        &-menu {
          display: none;
          li {
            padding: 0 $mpadding/2;
            &:last-child {
              padding-right: 0;
            }
            .button {
              cursor: pointer;
            }
            .button:not(.primary-button) {
              padding: $mpadding/3;
              color: #fff;
              text-shadow: 0 2px 2px #000;
            }
          }
        }
        .mbl-menu-btn {
          display: flex;
          width: 45px;
          height: 40px;
          border-radius: 8px;
          background: rgba(0, 0, 0, 0.25);
          justify-content: center;
          a {
            width: 30px;
            .line {
              margin: 6px 0;
              background-color: #fff;
              width: 100%;
              height: 3.5px;
              border-radius: 50px;
              -webkit-transform: rotate(0deg);
              -moz-transform: rotate(0deg);
              -o-transform: rotate(0deg);
              -ms-transform: rotate(0deg);
              transform: rotate(0deg);
              -webkit-transition: all 450ms ease-in;
              -moz-transition: all 450ms ease-in;
              -o-transition: all 450ms ease-in;
              transition: all 450ms ease-in;
            }
            .line:nth-child(2) {
              margin-left: 20%;
              width: 80%;
            }
          }
        }
      }
    }
    .router-link-exact-active:not(.logo) {
      border-bottom: solid 2px;
    }
  }

  *.onmenu .header .inner {
    .mbl-menu-btn a .line:nth-child(1) {
      -webkit-transform: translateY(398%) rotate(47deg);
      -ms-transform: translateY(398%) rotate(47deg);
      transform: translateY(398%) rotate(47deg);
    }
    .mbl-menu-btn a .line:nth-child(2) {
      opacity: 0;
    }
    .mbl-menu-btn a .line:nth-child(3) {
      -webkit-transform: translateY(-138%) rotate(134deg);
      -ms-transform: translateY(-138%) rotate(134deg);
      transform: translateY(-138%) rotate(134deg);
    }
  }

  .modulos-muestra {
    display: flex;
  }
  .logo {
    &-container {
      margin: 0 0 0 15px;
      width: 200px;
      overflow: hidden;
      line-height: 1.1;
      &-title {
        font-size: 20px;
        text-align: left;
        padding: 0px 15px;
      }
      &-sub {
        display: flex;
        transition: 3ms;
      }
      &-item {
        text-overflow: ellipsis;
        white-space: nowrap;
        animation:
          slider 13s linear infinite,
          opacitys 13s linear infinite;
        position: relative;
      }
      &-subitem {
        width: 200px;
        color: #fff;
        padding: 0 15px 2px;
      }
    }
  }

  @media (min-width: 1275px) {
    .header .inner {
      padding: 5px 0;
      padding-right: 25px;
      height: 70px;
      &-brand {
        img {
          max-height: 40px;
          margin: 0;
        }
      }
      &-nav {
        &-menu {
          display: flex;
        }
        .mbl-menu-btn {
          display: none;
        }
        &__languageModal {
          display: block;
          padding-left: $space-12;
          right: -4px;
        }
      }
    }
    .logo-container {
      &-title {
        font-size: 25px;
      }
    }
  }
</style>
