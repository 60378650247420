<script>
  import store from "../../store/index.js";
  import SignupButton from "./SignupButton.vue";
  import { mapActions, mapGetters } from "vuex";
  import ToggleLang from "./ToggleLang.vue";
  import { EventBus } from "../../helpers/eventBus";
  export default {
    name: "Mobilemenu",
    components: {
      SignupButton,
      ToggleLang,
    },
    data() {
      return {
        dataLang: {},
        isCheckedLang: false,
        timeOutChange: null,
      };
    },
    computed: {
      ...mapGetters(["getLocale"]),
      getAllData: async function () {
        this.getDataLang();
      },
      dataheader() {
        return this.dataLang.header || {};
      },
      socialMedia() {
        const media = this.dataLang?.resource?.social_media ?? [];
        return media.length ? this.$global.sortSocialMedia(this.dataLang?.resource?.social_media) : media;
      },
      datafooter() {
        return this.dataLang.footer || {};
      },
      langs() {
        return this.dataheader?.languages || [];
      },
    },
    methods: {
      ...mapActions(["changeLanguage"]),
      getDataLang: async function () {
        if (!store.getters["iflanguage"]) {
          return;
        }
        this.dataLang = await store.getters["dataLang"];
      },
      changeLang: async function (lang) {
        await this.changeLanguage(lang);
        this.$root.$emit("refreshlang", "Refresh Language");
      },
      closeMobileMenu: function () {
        const element = document.querySelector("body");
        element.classList.remove("onmenu");
      },
      sanitizeUrl(url) {
        window.open(url, "_blank");
      },
      toggleSwitch() {
        this.isCheckedLang = !this.isCheckedLang;
        clearTimeout(this.timeOutChange);
        this.timeOutChange = setTimeout(() => {
          this.changeLang(this.isCheckedLang ? "es" : "en");
          this.closeMobileMenu();
        }, 200);
      },
      goEmail(mail) {
        window.open(`mailto:${encodeURIComponent(mail)}`);
      },
      openMagazine() {
        this.closeMobileMenu();
        const params = {};

        if (this.$route.path !== "/") {
          params.path = "/";
          params.query = { openMagazine: true };
        }
        if (this.$route.hash !== "#ourcompany") {
          params.hash = "#ourcompany";
        }
        if (params.path || params.hash) {
          this.$router.push(params);
        } else {
          setTimeout(() => {
            EventBus.$emit("toggle-magazine-modal");
          }, 1000);
        }
      },
      goToSiblingApp(rootNav) {
        window.location.href = `/${rootNav}?lang=${this.getLocale}`;
      },
    },
    beforeMount() {
      this.isCheckedLang = this.getLocale === "es";
    },
  };
</script>

<template>
  <div class="mobileMenu" @click="closeMobileMenu" :set="(menu = dataheader.menu)">
    <div class="mobileMenu__categories" v-bind="getAllData">
      <ul class="mobileMenu__categoriesContainer" v-if="menu">
        <li class="mobileMenu__links" capitalize>
          <router-link class="mobileMenu__link" :to="{ path: '/', hash: '#home' }">
            {{ menu.home }}
          </router-link>
          <router-link class="mobileMenu__link" :to="{ path: '/', hash: '#services' }">
            {{ menu.services }}
          </router-link>
          <router-link class="mobileMenu__link" :to="{ path: '/webcast', hash: '#Webcast' }">
            {{ menu.webcast }}
          </router-link>
          <router-link class="mobileMenu__link" :to="{ path: '/gallery', hash: '#gallery' }">
            {{ menu.gallery }}
          </router-link>
          <a class="mobileMenu__magazine" @click="openMagazine">
            <div class="mobileMenu__box">
              <p class="mobileMenu__link">{{ menu.magazine }}</p>
            </div>
          </a>
          <div @click="() => goToSiblingApp('concurso')">
            <li class="mobileMenu__link">
              {{ menu.contest }}
            </li>
          </div>
          <router-link class="mobileMenu__link" :to="{ path: '/', hash: '#testimonials' }">
            {{ menu.testimonials }}
          </router-link>
          <div @click="() => goToSiblingApp('global')">
            <li class="mobileMenu__link">
              {{ menu.global }}
            </li>
          </div>
          <router-link class="mobileMenu__link" :to="{ path: '/blog', hash: '#blog' }">
            {{ menu.blog }}
          </router-link>
          <router-link ref="contactLink" class="mobileMenu__link" :to="{ path: '/contact', hash: '#contact' }">
            {{ menu.contact }}
          </router-link>
          <router-link class="mobileMenu__link" :to="{ path: '/jobs', hash: '#jobs' }">
            {{ menu.jobs }}
          </router-link>
          <router-link class="mobileMenu__link" :to="{ path: '/help', hash: '#help' }">{{ menu.faq }}</router-link>
        </li>
      </ul>
    </div>
    <div class="mobileMenu__toggle" v-show="langs.length">
      <ToggleLang :langs="langs" :isChecked="isCheckedLang" :toggleSwitch="toggleSwitch" />
    </div>
    <div class="mobileMenu__fotter">
      <signup-button class="mobileMenu__btnMovil" :title="menu.signup" v-if="menu"></signup-button>
      <div class="mobileMenu__social">
        <template v-for="(social, i) in socialMedia">
          <div class="mobileMenu__socialIcon" v-if="social.url" @click="sanitizeUrl(social.url)" :key="i">
            <iconic :svgCode="social.icon" />
          </div>
        </template>
      </div>
      <p class="mobileMenu__email" @click="goEmail(datafooter.email)" v-if="datafooter.email">{{ datafooter.email }}</p>
    </div>
  </div>
</template>

<style lang="scss">
  @property --angle {
    syntax: "<angle>";
    initial-value: 90deg;
    inherits: true;
  }

  @property --gradX {
    syntax: "<percentage>";
    initial-value: 50%;
    inherits: true;
  }

  @property --gradY {
    syntax: "<percentage>";
    initial-value: 0%;
    inherits: true;
  }

  :root {
    --transition: 2800ms;
    --angle: 90deg;
    --whiteBackgraund: rgba(255, 255, 255, 0.8);
    --blackBackground: rgba(255, 255, 255, 0);
  }

  @import "@/assets/styles/vars.scss";
  *.onmenu .mobileMenu {
    opacity: 1;
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0);
  }
  .mobileMenu {
    .router-link-active.mobileMenu__link {
      font-weight: 600;
    }
    .router-link-exact-active.mobileMenu__btnMovil {
      display: none;
    }
    position: fixed;
    height: 100%;
    width: 100vw;
    top: 0;
    left: 0;
    z-index: 190;
    padding: $mpadding 5px $mpadding $mpadding;
    opacity: 0;
    background-color: rgba(0, 0, 0, 0.88);
    transform: translateX(100%);
    transition: all 400ms ease-in;
    -webkit-transition: all 400ms ease-in;
    -o-transition: all 400ms ease-in;
    -webkit-transform: translateX(100%);
    -ms-transform: translateX(100%);
    &__categories {
      height: calc(100% - 125px);
      position: relative;
      padding-right: 15px;
      overflow-y: auto;
      overflow-x: hidden;
      &::-webkit-scrollbar {
        width: 3px;
      }
      &::-webkit-scrollbar-thumb {
        background: #FFF;
      }
    }
    &__toggle {
      position: absolute;
      top: 80px;
      left: 15px;
    }
    &__links {
      @include Flex(column, flex-start, flex-end);
      padding-top: 50px;
      gap: 20px;
    }
    &__link {
      font-size: 20px;
      color: #fff;
      text-transform: none;
      cursor: pointer;
    }
    &__fotter {
      @include Flex(column, flex-end, flex-end);
      height: 125px;
      gap: 15px;
      padding-right: 15px;
    }
    &__social {
      @include Flex(row);
      gap: 21px;
    }
    &__btnMovil {
      @include Flex(row);
      height: 35px;
      width: 119px;
      color: #fff;
      background-color: $primary-color;
      border-radius: 20px;
    }
    &__socialIcon {
      @include Flex(row);
      width: 25px;
      height: 25px;
      font-size: 0.9em;
      color: #fff;
      background-color: $primary-color;
      border-radius: 50%;
      box-shadow: 0px 2px 7px 0px rgba(0, 0, 0, 1);
    }
    &__email {
      color: #fff;
    }

    &__magazine {
      position: relative;
      left: 6px;
    }

    &__box {
      @include Flex(row, center, center);
      padding: $space-0 9px;
      position: relative;
      border-radius: $radius-40;
      z-index: 1;

      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border-radius: inherit;
        padding: $space-2;
        background: conic-gradient(from var(--angle), var(--blackBackground), var(--whiteBackgraund) 0.1turn, var(--whiteBackgraund) 0.15turn, var(--blackBackground) 0.25turn);
        z-index: -1;
        -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
        mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
        -webkit-mask-composite: xor;
        mask-composite: exclude;
        animation: borderRotate var(--transition) linear infinite forwards;
      }
    }

    @keyframes borderRotate {
      100% {
        --angle: 420deg;
      }
    }
    @media (min-width: 1275px) {
      display: none;
    }
  }
</style>
