<template>
  <div class="content">
    <Header v-if="isSignUpPath || screen.width < 768" />
    <router-view></router-view>
    <Footer v-if="isSignUpPath || screen.width < 768" />
    <div class="shadow-movil">
      <RegisterBtn class="content__register" />
      <InstagramBtn />
      <WhatsappBtn />
      <GalleryBtn />
      <Chat v-if="false" />
    </div>
    <MobileMenu />
  </div>
</template>

<script>
  import Header from "@/components/web/Header.vue";
  import Footer from "@/components/web/Footer.vue";
  import WhatsappBtn from "@/components/web/WhatsappBtn.vue";
  import RegisterBtn from "@/components/web/RegisterBtn.vue";
  import GalleryBtn from "@/components/web/GalleryBtn.vue";
  import MobileMenu from "@/components/web/MobileMenu.vue";
  import InstagramBtn from "@/components/web/InstagramBtn.vue";
  import SocketLanding from "../socket/socketLandig";
  import Chat from "../components/buttons/ChatAsesor.vue";
  import { mapActions } from "vuex";

  export default {
    components: {
      Header,
      Footer,
      RegisterBtn,
      WhatsappBtn,
      MobileMenu,
      GalleryBtn,
      InstagramBtn,
      Chat,
    },
    computed: {
      screen() {
        return this.$store.getters.screen;
      },
      isSignUpPath() {
        const dicRoutes = ["/signup", "/registrate", "/register"];
        return !dicRoutes.includes(this.$route.path);
      },
    },
    methods: {
      ...mapActions(["changeLanguage"]),
      conectSocketLanding() {
        const socket = SocketLanding.connect({});

        SocketLanding.on("connect", () => {
          console.log("Successfully connection");
          localStorage.setItem("SID", socket.id);
        });
      },
    },
    async beforeMount() {
      const { lang, ...rest } = this.$route.query;

      if (lang) {
        await this.changeLanguage(lang);
        this.$router.replace({ query: rest });
      }

      this.conectSocketLanding();
    },
  };
</script>
<style lang="scss">
  .content {
    &__register {
      margin-bottom: 60px;
    }
    .shadow-movil {
      display: flex;
      flex-direction: column;
      gap: 5px;
      position: fixed;
      z-index: 100;
      bottom: 25px;
      right: 10px;
      transform: translateY(0px);

      &:before {
        content: "";
        background: radial-gradient(ellipse, rgba(0, 0, 0, 0.35) 0%, rgba(0, 0, 0, 0) 80%);
        width: 92%;
        height: 20px;
        top: 100%;
        left: 2%;
        position: absolute;
        opacity: 1;
      }
    }
  }
</style>
