<template>
  <div noprint>
    <buttonRadar :classbutton="$store.state.isActiveHome ? 'register register--isActiveHome' : 'register'" iconic="register" refto="/signup#signup" />
  </div>
</template>
<script>
import buttonRadar from "@/components/buttons/ButtonRadar.vue";

export default {
  components: {
    buttonRadar,
  },
  watch: {
    $route(to, from) {
      if (to.fullPath.includes("/#home")) {
        this.$store.state.isActiveHome = true;
      } else {
        this.$store.state.isActiveHome = false;
      }
    },
  },
};
</script>

<style lang="scss"></style>
