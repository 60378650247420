import io from "socket.io-client";

class SocketService {
  constructor(payload) {
    if (SocketService.instance) {
      return SocketService.instance;
    }

    this.socket = null;
    this.host = payload.host;
    this.path = payload.path;
    this.reconnectInterval = null;

    SocketService.instance = this;
  }

  connect({ query, auth }) {
    if (!this.socket) {
      this.socket = io(this.host, {
        transports: ['websocket', 'polling'],
        reconnection: true,
        reconnectionAttempts: 20,
        reconnectionDelay: 10000,
        reconnectionDelayMax: 60000,
        randomizationFactor: 0.5,
        path: this.path,
        auth,
        query,
      });

      this.socket.on('connect', () => {
        console.log('Conectado al servidor');
        this.startReconnectCheck();
      });
      this.socket.on('disconnect', (reason) => {
        console.warn(`Desconectado: ${reason}`);
        this.stopIntervals();
      });
      this.socket.on('error', (error) => {
        console.error('Error de conexión:', error);
      });
    }

    return this.socket;
  }

  disconnect() {
    if (this.socket) {
      this.socket.disconnect();
      this.stopIntervals();
    }
  }

  startReconnectCheck() {
    if (this.reconnectInterval) return; 
    this.reconnectInterval = setInterval(() => {
      if (!this.socket.connected && !this.socket.connecting) {
        console.warn('Intentando reconectar...');
        this.socket.connect();
      }
    }, 60000); 
  }

  stopIntervals() {
    if (this.reconnectInterval) {
      clearInterval(this.reconnectInterval);
      this.reconnectInterval = null;
    }
  }

  on(eventName, callback) {
    if (this.socket) {
      this.socket.on(eventName, callback);
    }
  }

  getSocket() {
    return this.socket;
  }
}

export default SocketService;
