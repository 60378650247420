<script>
  import { mapGetters } from "vuex";
  import data from "../../dictionary/questions.json";
  import ModalAsesor from "../modals/ModalAsesorChat.vue";

  export default {
    components: { ModalAsesor },
    data() {
      return {
        show: false,
        isChatClosed: true,
        timeOutMessage: null,
      };
    },
    computed: {
      ...mapGetters(["getLocale"]),
      msgHelp() {
        return data[this.getLocale]?.msgHelp || "";
      },
    },
    methods: {
      openCloseChat() {
        this.isChatClosed = !this.isChatClosed;
        this.show = false;

        this.$emit("toggleModal", this.isChatClosed);
      },
      showHelp(show) {
        this.resetTimeOut();
        this.timeOutMessage = setTimeout(() => {
          this.show = this.isChatClosed ? show : false;
          this.showHelp(!this.show);
        }, 30000);
      },
      resetTimeOut() {
        clearTimeout(this.timeOutMessage);
        this.timeOutMessage = null;
      },
    },
    mounted() {
      this.showHelp();
    },
    beforeDestroy() {
      this.resetTimeOut();
    },
  };
</script>
<template>
  <div class="ChatAsesor">
    <div :class="[isChatClosed ? 'ChatAsesor__container' : 'ChatAsesor__close', 'ChatAsesor__transition']" @click="openCloseChat">
      <img class="ChatAsesor__image" v-if="isChatClosed" src="https://new-web-site-bucket.s3.amazonaws.com/website/networks/AvatarChat.png" alt="AvatarChat" />
      <iconic name="close" v-else />
    </div>
    <template v-if="show">
      <p class="ChatAsesor__tooltip">{{ msgHelp }}</p>
      <div class="ChatAsesor__end" />
    </template>
    <ModalAsesor v-if="!isChatClosed" class="ChatAsesor__modalAsesor" />
  </div>
</template>

<style lang="scss">
  .ChatAsesor {
    position: relative;
    cursor: pointer;
    &__container,
    &__close {
      @include Flex();
      width: 50px;
      height: 50px;
      border-radius: 100% 100% 100% 0%;
      border: 2px solid $primary-color;
      background-color: #fff;
    }
    &__transition {
      transition: 0.5s ease;
    }
    &__close {
      border-radius: 100% 0% 100% 100%;
      transition: 0.3s border-radius ease;
      font-size: 30px;
    }
    &__image {
      margin-top: 5px;
      margin-right: 3px;
      width: 35px;
      height: 35px;
      object-fit: contain;
    }
    &__tooltip {
      width: 230px;
      position: absolute;
      right: 75px;
      font-size: 14px;
      padding: 5px;
      color: #fff;
      background-color: #000000a1;
      border-radius: $radius-6 $radius-0 5px $radius-6;
      box-shadow: 0px 1px 4px 0px rgba(69, 69, 69, 0.25);
      transition: opacity 0.9s ease, visibility 0.9s ease;
    }
    &__end {
      position: relative;
      width: 0;
      height: 10px;
      border-style: solid;
      border-width: 11px 11px 0 0;
      border-color: rgba(0, 0, 0, 0.631372549) transparent transparent transparent;
      transform: rotate(360deg);
      right: 18px;
      top: 0;
    }
    &__modalAsesor {
      position: absolute;
      right: 8px;
      bottom: 55px;
      z-index: 140;
    }
    @media (max-width: 430px) {
      &__close {
        font-size: 35px;
        padding-top: $space-2;
      }
    }
  }
</style>
