import SocketService from "../common/CompositionAPI/socket/index";

const host = `${process.env.VUE_APP_SOCKET}/landingSocket`;
const payloadSocket = { host, path: "/api/v1/socket.io" };

class ExtendedSocketService extends SocketService {
  constructor(payload) {
    super(payload);
  }
}

export default new ExtendedSocketService(payloadSocket);
