<script>
export default {
  props: ["langs", "isChecked", "toggleSwitch"],
  data() {
    return {
      availableLangs: {
        es: {},
        en: {},
      },
    };
  },
  computed: {
    langss() {
      this.langs.forEach((lang) => this.availableLangs[lang.languageId] && (this.availableLangs[lang.languageId] = lang));
      return this.availableLangs;
    },
  },
};
</script>

<template>
  <div :class="`switch switch--${isChecked ? 'checked' : ''}`" @click.stop="toggleSwitch">
    <div v-for="(lang, index) in langss" :key="index" class="mobile-menu-lang-item">
      <img :class="`switch__lang switch__lang--${lang.languageId}`" :src="lang.linkFlag" :alt="`flag ${lang.languageId}`" />
    </div>
    <div :class="`switch__slider switch__slider--${isChecked ? 'checked' : ''}`" />
  </div>
</template>

<style scoped lang="scss">
.switch {
  @include Flex(row, space-between);
  position: relative;
  width: 74px;
  height: 37px;
  padding: 10px;
  background: #ffffffb0;
  border-radius: 20px;
  cursor: pointer;
  &__lang {
    position: absolute;
    height: 20px;
    width: 20px;
    top: 0;
    bottom: 0;
    margin: auto;
    border-radius: 3px;
    &--en {
      left: 10px;
    }
    &--es {
      right: 10px;
    }
  }
  &__slider {
    position: absolute;
    height: 28px;
    width: 28px;
    top: 0;
    bottom: 0;
    left: 6px;
    margin: auto;
    background: white;
    border: 1px solid $chicago;
    border-radius: 50%;
    transition: 0.2s ease-in-out;
    &--checked {
      transform: translateX(35px);
    }
  }
}
</style>
