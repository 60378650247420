<script>
  import { mapGetters } from "vuex";
  import data from "../../dictionary/questions.json";
  export default {
    data() {
      return {
        selectedQuestionIndex: true,
        question: [],
        answer: "",
        image: "",
        link: "",
        items: [],
        btn: "aqui",
        timeResponse: true,
      };
    },
    computed: {
      ...mapGetters(["getLocale"]),
      optionsLinks() {
        return this.dataChat.options.links;
      },
      dataChat() {
        const { questionsList, answearList: answersList, gretting, icon, title, signup, menu, options } = data[this.getLocale];

        return { questionsList, answersList, gretting, icon, title, signup, menu, options };
      },
    },
    methods: {
      navigateTo(url) {
        const isExternal = url.startsWith("https");
        const isInternal = url.startsWith("/");

        if (isExternal) {
          window.open(url, "_blank", "noopener");
        } else if (isInternal) {
          this.$router.push(url);
        } else {
          console.error("La URL o ruta proporcionada no es válida");
        }
      },
      scrollToBottom() {
        const container = this.$el.querySelector(".ModalAsesorChat__container");
        if (container) {
          container.scrollTop = container.scrollHeight;
        }
      },
      selectQuestion(index) {
        setTimeout(() => {
          this.timeResponse = false;
        }, 2000);
        const selectedAnswer = this.dataChat.answersList[index];
        const selectedQuestion = this.dataChat.questionsList[index];

        this.answer = selectedAnswer[`answer${index + 1}`];
        this.image = selectedAnswer.img;
        this.link = selectedAnswer.link;
        this.items = selectedAnswer.items;
        this.btn = selectedAnswer.btn;
        this.question = selectedQuestion[`question${index + 1}`];
        this.selectedQuestionIndex = false;
        this.timeResponse = true;

        this.$nextTick(() => {
          this.scrollToBottom();
        });
      },
      goTo() {
        this.selectedQuestionIndex = true;
      },
      goToSignup() {
        this.$router.push("/signup#signup");
      },
    },
  };
</script>
<template>
  <div class="ModalAsesorChat" ref="modalAsesor">
    <div class="ModalAsesorChat__title">
      <p class="ModalAsesorChat__models">{{ dataChat.title }}</p>
    </div>
    <div class="ModalAsesorChat__container">
      <div class="ModalAsesorChat__welcome">
        <div class="ModalAsesorChat__image1">
          <img class="ModalAsesorChat__image" :src="dataChat.icon" alt="Chat" />
        </div>
        <p class="ModalAsesorChat__text">{{ dataChat.gretting }}</p>
      </div>
      <div class="ModalAsesorChat__questions" v-if="selectedQuestionIndex">
        <div class="ModalAsesorChat__generalInfo">
          {{ dataChat.options.title }}
          <div v-for="(item, index) in optionsLinks" :key="index">
            <div @click="navigateTo(item.link)">{{ item.text }}</div>
          </div>
        </div>
        <div class="ModalAsesorChat__question" v-for="(question, index) in dataChat.questionsList" :key="index">
          <p class="ModalAsesorChat__quest" @click="selectQuestion(index)">{{ question["question" + (index + 1)] }}</p>
        </div>
      </div>
      <div v-else-if="timeResponse">
        <div class="ModalAsesorChat__question">
          <p class="ModalAsesorChat__quest">{{ question }}</p>
        </div>
        <div class="ModalAsesorChat__animate">
          <div class="ModalAsesorChat__image1">
            <img class="ModalAsesorChat__image" :src="dataChat.icon" :alt="dataChat.title" />
          </div>
          <div class="ModalAsesorChat__ball"></div>
          <div class="ModalAsesorChat__ball"></div>
          <div class="ModalAsesorChat__ball"></div>
        </div>
      </div>
      <div v-else>
        <div class="ModalAsesorChat__questions">
          <div class="ModalAsesorChat__question">
            <p class="ModalAsesorChat__quest">{{ question }}</p>
          </div>
          <div class="ModalAsesorChat__welcome">
            <div class="ModalAsesorChat__image1">
              <img class="ModalAsesorChat__image" :src="dataChat.icon" :alt="dataChat.title" />
            </div>
            <div class="ModalAsesorChat__text">
              {{ answer }}
              <div class="" v-for="(item, index) in items" :key="index">
                <p class="">{{ item }}</p>
              </div>
              <img v-if="image" class="ModalAsesorChat__bonds" :src="image" alt="hell" />
              <span class="ModalAsesorChat__link" v-if="link" target="_blank" @click="navigateTo(link)">{{ btn }}</span>
            </div>
          </div>
          <div @click="goToSignup" class="ModalAsesorChat__signup">
            {{ dataChat.signup }}
            <iconic name="arrowRight" class="ModalAsesorChat__arrow" />
          </div>
          <div @click="goTo" class="ModalAsesorChat__menu">
            <iconic name="barsMenu"></iconic>
            <p class="ModalAsesorChat__menu--text">{{ dataChat.menu }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss">
  .ModalAsesorChat {
    display: grid;
    grid-template-rows: auto 1fr;
    z-index: 20;
    width: 350px;
    height: 410px;
    border-radius: $radius-12 $radius-12 $radius-0 $radius-12;
    background: #fff;
    box-shadow: -2px 0 8px 2px #45454540;
    &__title {
      border-radius: $radius-12 $radius-12 $radius-12 $radius-0;
      background: $primary-color;
    }
    &__models {
      padding: 10px $space-20;
      font-size: 20px;
      color: #fff;
    }
    &__container {
      @include Flex(column);
      overflow: auto;
      margin: 10px 5px;
      &::-webkit-scrollbar {
        width: 7px;
      }
      &::-webkit-scrollbar-thumb {
        background-color: $primary-color;
        border-radius: none;
      }
      &::-webkit-scrollbar-track {
        background-color: #eee;
        border-radius: none;
      }
    }
    &__welcome {
      display: flex;
    }
    &__image1 {
      width: 50px;
      height: 50px;
      border: 2px solid $primary-color;
      background-color: #fff;
      display: flex;
      justify-content: center;
      border-radius: 50%;
    }
    &__image {
      margin: auto;
      width: 35px;
      height: 35px;
      object-fit: contain;
    }
    &__text {
      padding: $space-6;
      font-size: 14px;
      margin-left: 15px;
      max-width: 250px;
      color: #4d3c46;
      background: #bd09090d;
      border-radius: 10px 10px 10px $radius-0;
    }
    &__questions {
      padding: 5px;
      height: 195px;
    }
    &__question {
      cursor: pointer;
      width: 220px;
      color: #4d3c46;
      border: 1px solid #bd0909a3;
      font-size: 14px;
      border-radius: 15px;
      padding: 5px 10px;
      margin: $space-8 $space-0 $space-12 95px;
      &:hover {
        background: rgba(189, 9, 9, 0.08);
        opacity: 0.9;
      }
    }
    &__quest {
      text-align: center;
    }
    &__answear {
      margin-left: 10px;
      padding: $space-6;
      font-size: 15px;
      max-width: 270px;
      color: #4d3c46;
      background: #bd09090d;
      border-radius: 10px 10px 10px $radius-0;
    }
    &__bonds {
      width: 235px;
      border-radius: $space-12;
    }
    &__options {
      margin: 10px;
      font-size: 16px;
    }
    &__link {
      cursor: pointer;
      color: $primary-color;
      text-decoration: underline;
    }
    &__arrow {
      margin-left: $space-6;
    }
    &__animate {
      margin-top: $space-16;
      margin-right: 200px;
      @include Flex();
      gap: $space-8;
      :nth-child(1) {
        animation-delay: 0.6s;
      }
      :nth-child(2) {
        animation-delay: 0.3s;
      }
      :nth-child(3) {
        animation-delay: 0s;
      }
      @keyframes wave {
        0%,
        100% {
          transform: scale(1);
          opacity: 1;
        }
        50% {
          transform: scale(1.5);
          opacity: 0.5;
        }
      }
    }
    &__ball {
      width: 13px;
      height: 13px;
      border-radius: 50%;
      background: $primary-color;
      animation: wave 1.5s ease-in-out infinite;
    }
    &__generalInfo {
      max-width: 250px;
      color: #4d3c46;
      border-radius: $radius-0 10px 10px 10px;
      padding: 5px 10px;
      margin: $space-2 $space-0 $space-12 65px;
      font-size: 14px;
      background: #bd09090d;
    }
    &__signup {
      text-align: center;
      background: $primary-color;
      color: #fff;
      border: solid 1px $primary-color;
      width: max-content;
      height: 35px;
      border-radius: $radius-20;
      padding: $space-8 $space-16;
      cursor: pointer;
      margin-top: 10px;
      margin-left: 65px;
    }
    &__menu {
      @include Flex();
      gap: 10px;
      width: fit-content;
      padding: 10px 10px;
      cursor: pointer;
      border-radius: 10px;
      background: #bd09090d;
      transition: 0.2s ease-in;
      margin-top: 10px;
      font-size: 13px;
      margin-left: 65px;
      &:hover {
        background: #bd09090d;
      }
    }
  }
</style>
